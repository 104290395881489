import { ConsoleDebug } from './ConsoleDebug';
import { Environment} from "./Environment";

const REGEX_REDIRECT_URI = /redirecturi[,=]+([^_&]*)(\.html$|_.*)?/;
const REGEX_REDIRECT_URI_BASE64 = /#([A-Za-z0-9+\/=]*)/;

const REST_ENDPOINT_QUOTASTATUS = '/REST/community/quotastatus';
const REST_ENDPOINT_ACCOUNT = '/REST/community/account';
const REST_ENDPOINT_NEWSLETTER_SUBSCRIBE = '/REST/community/newsletter/subscribe';
const REST_ENDPOINT_NEWSLETTER_UNSUBSCRIBE = '/REST/community/newsletter/unsubscribe'
const REST_ENDPOINT_PIANO_CHECKOUT_COMPLETE = '/REST/payment/pianocheckoutcomplete';

const GENERAL_ERROR_MESSAGE = 'Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es später noch einmal.';

export class RedfactHelper {

  /**
   * constructor to create Environment instance
   * @return {object} RedfactHelper instance
   */
  constructor() {
    this.logInstance = new ConsoleDebug('debug-redfact', 'RedfactHelper');
    //this.logInstance.disableDebugMode();
    this.env = new Environment();
  }

  // check if tool is enabled in backend
  checkBackendApproval(toolToken) {
    var toolEnabled = false;
    if ( typeof window.nfyJsonParams == "object" && window.nfyJsonParams[toolToken] ) {
      toolEnabled = true;
    } else {
      var paramsNode = document.querySelector('#nfy-params');
      if ( paramsNode != null && paramsNode.dataset.parameters != undefined ) {
        var nfyJsonParamsTmp = JSON.parse(paramsNode.dataset.parameters);
        if ( typeof nfyJsonParamsTmp == "object" && nfyJsonParamsTmp[toolToken] ) {
          toolEnabled = true;
        }
      }
    }

    if(toolToken == true) {
      toolEnabled = true;
    }

    return toolEnabled;
  }

  /**
   * base64 encoding examples
   * https://test8.mannheimer-morgen.de/login.html#aHR0cHM6Ly90ZXN0OC5tYW5uaGVpbWVyLW1vcmdlbi5kZS9vcnRlL21hbm5oZWltX3B1aWQsMy5odG1s
   * aHR0cHM6Ly90ZXN0OC5tYW5uaGVpbWVyLW1vcmdlbi5kZS9vcnRlL21hbm5oZWltX3B1aWQsMy5odG1s = https://test8.mannheimer-morgen.de/orte/mannheim_puid,3.html
   */
  getRedirectUriBase64 () {
    let redirectUri = false;

    try {
      const locationHrefDecodedURI = decodeURIComponent(window.location.hash);
      const match1 = locationHrefDecodedURI.match(REGEX_REDIRECT_URI_BASE64);
      //this.logInstance.log('window.location.hash', window.location.hash);
      //this.logInstance.log('decodeURIComponent(window.location.hash)', locationHrefDecodedURI);
      //this.logInstance.log('locationHrefDecodedURI.match(' + REGEX_REDIRECT_URI_BASE64 + ')', match1);
      if ( match1 !== null ) {
        redirectUri = atob(match1[1]);
      }
    } catch (error) {
      this.logInstance.log(error, match1[1]);
    }

    return redirectUri;
  }

  /**
   * Diese Funktion könnte für den E-Paper Browserversion Login genutzt werden
   *
   * Funktionierende Beispiel URI in location.href
   * https://www.mannheimer-morgen.de/login_redirecturi,https%3A++e-paper.mannheimer-morgen.de+titles+.html
   * https://xmedias6.mannheimer-morgen.de/login_redirecturi,https%3A++e-paper.mannheimer-morgen.de+titles+_puid,3.html
   * https://red.mannheimer-morgen.de/index.php?redirecturi=https%3A%2F%2Fe-paper.mannheimer-morgen.de%2Ftitles%2F&puid=3&pageid=2683
   * https://xmedias6.mannheimer-morgen.de/index.php?redirecturi=https%3A%2F%2Fe-paper.mannheimer-morgen.de%2Ftitles%2F&puid=3&pageid=2683
   *
   * Fehleranfällig, weil bei einigen redirect uri ja auch ein Unterstrich vorkommen kann z.B. hier
   * https://xmedias3.mannheimer-morgen.de/login_redirecturi,https%3A++xmedias3.mannheimer-morgen.de+startseite_puid%2C3.html_puid,3.html
   *
   * @returns {boolean}
   */
  getEpaperRedirectUri() {
    const match = window.location.href.match(REGEX_REDIRECT_URI);
    this.logInstance.log('window.location.href.match(' + REGEX_REDIRECT_URI + ')', match);
    if ( match !== null
      && match[1].includes('e-paper') )
    {
      let redirectUri = match[1];
      redirectUri = redirectUri.replaceAll('+', '/');
      redirectUri = redirectUri.replaceAll('%2F', '/');
      redirectUri = redirectUri.replaceAll('%3A', ':');
      this.logInstance.log('redirectUri', redirectUri);
      this.logInstance.log('decodeURI(redirectUri)', decodeURI(redirectUri));
      return decodeURI(redirectUri);
    }
    return false;
  }

  /**
   * Get redfact environment-specific hostname and scheme for endpoints
   * @returns {string} e.g. 'https://www.mannheimer-morgen.de'
   */
  getRedfactEndpointHostname () {
    let hostname;
    if ( typeof window.nfyDomain === 'string'
      && window.nfyDomain !== '' )
    {
      // best case
      hostname = window.nfyDomain;
    } else if ( this.env.isTestDatabaseEnvironment() ) {
      // fallback test env
      hostname = 'https://integration.' + this.env.getDomain();
    } else {
      // fallback live/prod env
      hostname = 'https://www.' + this.env.getDomain();
    }
    return hostname;
  }

  /**
   * Get redfact full endpoint with environment-specific hostname and scheme
   * @returns {string} e.g. https://xmedias3.mannheimer-morgen.de/REST/community/quotastatus?puid=3
   */
  getRestEndpointQuotastatus () {
   return this.getRedfactEndpointHostname() + REST_ENDPOINT_QUOTASTATUS + '?puid=' + this.env.getCurrentPuId();
  }

  /**
   * Get redfact full endpoint with environment-specific hostname and scheme
   * @returns {string} e.g. https://xmedias3.mannheimer-morgen.de/REST/community/account?puid=3
   */
  getRestEndpointAccount () {
   return this.getRedfactEndpointHostname() + REST_ENDPOINT_ACCOUNT + '?puid=' + this.env.getCurrentPuId();
  }

  /**
   * Get redfact full endpoint with environment-specific hostname and scheme
   * @returns {string} e.g. https://xmedias3.mannheimer-morgen.de/REST/newsletter/subscribe
   */
  getRestEndpointNewsletterSubscribe () {
   return this.getRedfactEndpointHostname() + REST_ENDPOINT_NEWSLETTER_SUBSCRIBE;
  }

  /**
   * Get redfact full endpoint with environment-specific hostname and scheme
   * @returns {string} e.g. https://xmedias3.mannheimer-morgen.de/REST/newsletter/unsubscribe
   */
  getRestEndpointNewsletterUnsubscribe () {
   return this.getRedfactEndpointHostname() + REST_ENDPOINT_NEWSLETTER_UNSUBSCRIBE;
  }

  /**
   * Get redfact full endpoint with environment-specific hostname and scheme
   * @returns {string} e.g. https://xmedias3.mannheimer-morgen.de/REST/payment/pianocheckoutcomplete
   */
  getRestEndpointPianoCheckoutComplete () {
   return this.getRedfactEndpointHostname() + REST_ENDPOINT_PIANO_CHECKOUT_COMPLETE;
  }

  isValidUrl (url) {
    try {
      return Boolean(new URL(url));
    } catch(e){
      return false;
    }
  }

  /**
   * Nur redfact, www2 oder e-paper hostnamen sind valide und als redirect-ziele zugelassen
   * @param url string z.B. https://test8.mannheimer-morgen.de/orte/mannheim_puid,3.html
   * @returns {boolean}
   */
  isValidHaasUrl (url) {
    const valid = this.isValidUrl(url) && this.env.isValidHaasHostname(url);
    if ( !valid ) {
      this.logInstance.log('isValidHaasUrl() url invalid  ', url);
    }
    return valid;
  }

  getGeneralErrorMessage () {
    return GENERAL_ERROR_MESSAGE;
  }
}
